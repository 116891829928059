import React from 'react';
import FooterSlider from './FooterSlider';
import ScrollToTop from './ScrollToTop';
import BackToTopButton from './BakctoTop';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file

const Locations = () => {
  const locations = [
    { name: 'Baltimore', image: '/upload/Baltimore.jpg', path: '/Baltimore' },
    { name: 'Maryland', image: '/upload/Maryland.jpg', path: '/Maryland' },
    { name: 'Washington DC', image: '/upload/Washington DC.jpg', path: '/WashingtonDC' },
    { name: 'New Orleans LA', image: '/upload/New Orleans LA.jpg', path: '/NewOrleansLA' },
    { name: 'Philadelphia PA', image: '/upload/Philadelphia PA.png', path: '/PhiladelphiaPA' },
    { name: 'Chicago IL', image: '/upload/Chicago IL.jpg', path: '/ChicagoIL' },
  ];

  return (
    <div className="content-wrapper">
      <ScrollToTop />
      <BackToTopButton />
      <div className="limoking-content">
        <div className="locations-container">
          {locations.map((location, index) => (
            <Link to={location.path} key={index} className="location-card">
              <img src={location.image} alt={location.name} className="location-image" />
              <div className="overlay">
                <h3 className="location-name">{location.name}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <FooterSlider />
    </div>
  );
};

export default Locations;
