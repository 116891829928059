/* eslint-disable jsx-a11y/anchor-is-valid */
import 'flexslider';
import 'flexslider/flexslider.css';
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
import { BrowserRouter } from 'react-router-dom';
import Slider from 'react-slick'
import { useLocation } from 'react-router-dom'
import { FaFacebook, FaPhoneAlt } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { BiMapPin } from 'react-icons/bi';
import { BsFacebook, BsInstagram, BsLinkedin, BsX, BsYoutube } from 'react-icons/bs';
import SocialIcon from './icon/Icon';



const Layout = (props) => {

    useEffect(() => {
        AOS.init();
    }, []);

    const location = useLocation()
    const navigate = useNavigate();


    console.log(location.pathname);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const [active, setActive] = useState(false);
    const [next, setNext] = useState(false);

    // const PreviousBtn = (props) => {
    //   console.log(props);
    //   const { className, onClick } = props;
    //   return (
    //     <div className={className} onClick={onClick}>
    //       <lessThan style={{ color: "white", fontSize: "30px" }} />
    //     </div>
    //   );
    // };

    const isMobile = typeof window !== 'undefined' && window.innerWidth < 770

    console.log(active);
    const handleClick = () => {
        // console.log('ok');
        // if (active) {
        setActive(!active)
        // } else {
        // setActive(true)
        // }
    }

    useEffect(() => {
        setActive(false)

    }, [location.pathname]);

    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const triggerPoint = window.innerHeight * 0.05; // 20% of viewport height
            setIsFixed(scrollTop > triggerPoint);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll); // Cleanup
    }, []);


    const Reservations = [
        {
            value:"Booknow",
            route:'/BookNow'
        },
        {
            value:"Locations",
            route:'/Locations'
        },
        
    ]

    const Special = [
        {
            value:"Weeding limo",
            route:'/Weedinglimo'
        },
        {
            value:"Airport Transfers",
            route:'/Airporttransfers'
        },
        {
            value:"Wine Tours",
            route:'/Winetours'
        },
        {
            value:"Party Like Rockstar",
            route:'/Partylikerockstar'
        },
        {
            value:"Corporate Travel",
            route:'/Corporatetravel'
        },
        {
            value:"Prom and Graduation",
            route:'/PromGraduation'
        },
        
    ]

    const cities = [
        // "Associates",
        // "Chauffeur Partners US",
        // "FBO Partners",
        // "Booking Guide",
        {
            value: "Blog",
            route: '/blog'
        },
        {
            value: "Contact",
            route: '/contact-us'
        },

        // "FAQ",
        // "Contact"
    ]



    return (
        <div>
            <header >
                <div style={{ display: 'flex', justifyContent: 'space-between', background: '#232323', width: '100%', alignItems: 'center' }}>
                    <div >
                    </div>
                    <div style={{ display: 'flex', width: isMobile ? '100%' : '56%', justifyContent: 'space-between', padding: isMobile ? '25px' : '25px 45px 25px 0px', alignItems: 'center' }}>
                        <div>
                            <Link to='/' >
                                <img style={{ width: isMobile ? '140px' : '140px', height: isMobile ? '110px' : '100px', cursor: 'pointer' }} src="upload/FooterLogo.png" alt="logo" />
                            </Link>
                        </div>
                        {
                            isMobile ?

                                <div style={{ background: '#303030', padding: '10px', zIndex: '2000' }} onClick={handleClick}> <div style={{ padding: '10px' }} class="menu-btn">
                                    <div class="line" ></div>
                                    <div class="line"></div>
                                    <div class="line"></div>
                                </div> </div> : <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} >

                                <div>
                                        <img
                                            src="upload/phone-icon.png"
                                            alt="phone-icon"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                        <a href="tel:+16674000092" style={{ textDecoration: "none" }}>
                                        <span
                                            style={{
                                                color: "#ffffff",
                                                marginLeft: 11,
                                                fontSize: 15,
                                                lineHeight: "1.1"
                                            }}
                                        >
                                            +1 667-400-0092
                                        </span>{" "}
                                        </a>
                                    </div>
                                    <div>
                                        <img
                                            src="upload/envelope-icon.png"
                                            alt="envelope-icon"
                                            style={{ verticalAlign: "middle" }}
                                        />{" "}
                                        <a
                                           href="mailto:info@92Limo.com"
                                           style={{ textDecoration: "none" }}
                                          >
                                        <span
                                            style={{
                                                color: "#ffffff",
                                                marginLeft: 11,
                                                fontSize: 15,
                                                lineHeight: "1.1"
                                            }}
                                        >
                                            info@92Limo.com
                                        </span>
                                        </a>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
                {
                    active && isMobile && (
                        <div data-aos="fade-up"
                            data-aos-delay="100" style={{ zIndex: '1000' }} id="modal" class="hidden">
                            <div class="modal-content">
                                <div >
                                    <nav className="limoking-navigation" id="limoking-main-navigation">
                                        <ul id="menu-main-menu-1" style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', gap: '20px', flexDirection: 'column' }} className="sf-menu limoking-main-menu">
                                            <li style={{ fontWeight: '700' }} className="menu-item current-menu-item limoking-normal-menu">
                                                <Link to='/'>Home</Link>
                                            </li>
                                            <li style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => navigate('/about-us')} className="menu-item menu-item-has-childrenmenu-item menu-item-has-children limoking-normal-menu">
                                                <a className="sf-with-ul-pre">
                                                    About Us
                                                </a>
                                            </li>
                                            {/* <li style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => navigate('/blog')} className="menu-item menu-item-has-childrenmenu-item menu-item-has-children limoking-normal-menu">
                                                <a className="sf-with-ul-pre">
                                                    Blogs
                                                </a>
                                            </li> */}
                                            <li style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => navigate('/BookNow')} className="menu-item menu-item limoking-normal-menu">
                                            <a >Book Now</a>
                                            </li>
                                            <li style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => navigate('/contact-us')} className="menu-item menu-item limoking-normal-menu">
                                                <a >Contact us</a>
                                            </li>
                                            <li style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => navigate('/privacy-policy')} className="menu-item menu-item limoking-normal-menu">
                                                <a >Privacy Policy</a>
                                            </li>
                                            <li style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => navigate('/terms-conditions')} className="menu-item menu-item limoking-normal-menu">
                                                <a >Terms and Conditions</a>
                                            </li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    isMobile && (<div style={{ background: '#232323', display: 'flex', flexDirection: 'column', gap: '15px', justifyContent: 'center', padding: '25px' }} >
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src="upload/phone-icon.png"
                                alt="phone-icon"
                                style={{ verticalAlign: "middle" }}
                            />
                            <span
                                style={{
                                    color: "#ffffff",
                                    marginLeft: 11,
                                    fontSize: 15,
                                    lineHeight: "1.1"
                                }}
                            >
                                +1 667-400-0092
                            </span>{" "}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src="upload/envelope-icon.png"
                                alt="envelope-icon"
                                style={{ verticalAlign: "middle" }}
                            />{" "}
                            <span
                                style={{
                                    color: "#ffffff",
                                    marginLeft: 11,
                                    fontSize: 15,
                                    lineHeight: "1.1"
                                }}
                            >
                                info@92Limo.com
                            </span>
                        </div>
                    </div>)
                }
                {
                    !isMobile && (
                        <div
                            style={{
                                background: isFixed ? '#232323' : 'rgba(35,35,35,.75)',
                                width: '100%',
                                position: isFixed ? 'fixed' : 'absolute', // Dynamic positioning
                                top: isFixed ? 0 : 'unset', // Adjust top position when fixed
                                transition: 'all 0.3s ease-in-out',
                                zIndex: 1000, // Ensure navigation bar stays on top
                            }}
                        >
                            <nav className="limoking-navigation" id="limoking-main-navigation">
                                <ul id="menu-main-menu-1" className="sf-menu limoking-main-menu" style={{ padding: isFixed ? '10px' : '0px' }}>
                                    {
                                        isFixed && (
                                            <Link to='/' >
                                                <img style={{ height: '70px', cursor: 'pointer' }} src="upload/FooterLogo.png" alt="logo" />
                                            </Link>
                                        )
                                    }
                                    <li className="menu-item current-menu-item limoking-normal-menu">
                                        <Link to='/'>Home</Link>
                                    </li>
                                    <li onClick={() => navigate('/about-us')} className="menu-item menu-item-has-childrenmenu-item menu-item-has-children limoking-normal-menu">
                                        <a className="sf-with-ul-pre">About Us</a>
                                    </li>
                                    {/* <li onClick={() => navigate('/blog')} className="menu-item menu-item-has-childrenmenu-item menu-item-has-children limoking-normal-menu">
                                        <a className="sf-with-ul-pre">Blogs</a>
                                    </li> */}
                                    <li style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => navigate('/BookNow')} className="menu-item menu-item limoking-normal-menu">
                                            <a >Book Now</a>
                                            </li>
                                    <li onClick={() => navigate('/contact-us')} className="menu-item menu-item limoking-normal-menu">
                                        <a>Contact us</a>
                                    </li>
                                    <li onClick={() => navigate('/privacy-policy')} className="menu-item menu-item limoking-normal-menu">
                                        <a>Privacy Policy</a>
                                    </li>
                                    <li onClick={() => navigate('/terms-conditions')} className="menu-item menu-item limoking-normal-menu">
                                        <a>Terms and Conditions</a>
                                    </li>
                                </ul>
                                <div className="limoking-menu-search" id="limoking-menu-search">
                                    <form method="get" id="searchform" action="#" name="searchform">
                                        <div className="search-text">
                                            <input
                                                type="text"
                                                defaultValue="Type Keywords"
                                                name="s"
                                                autoComplete="off"
                                                data-default="Type Keywords"
                                            />
                                        </div>
                                        <input type="submit" defaultValue="" />
                                        <div className="clear" />
                                    </form>
                                </div>
                            </nav>
                        </div>
                    )
                }
            </header>
            {props.children}
            <footer className="footer-wrapper">
                <div className="footer-container container">
                    <div className="footer-column three columns" id="footer-widget-1">
                        <div
                            id="text-5"
                            className="widget widget_text limoking-item limoking-widget"
                        >
                            <p style={{ marginBottom: '5px' }}>
                                <img src="upload/FooterLogo.png" style={{ width: 170, cursor: 'pointer' }} alt="logo" />
                            </p>
                            <div className="textwidget">
                                <p style={{ fontWeight: '600' }}>
                                    Experience Gold & Confort on your travels
                                </p>
                                <h3 className="limoking-widget-title">Contact Info</h3>
                                <div className="textwidget" style={{ marginBottom: '6px' }}>
                                    <FaPhoneAlt /> +1 667-400-0092
                                </div>
                                <div className="textwidget" style={{ marginBottom: '6px' }}>
                                    +1 667 677 0044
                                </div>


                                <div className="textwidget">
                                    <TfiEmail /> info@92Limo.com
                                </div>
                                
                                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                    <a href='https://www.facebook.com/profile.php?id=100095592151518&mibextid=LQQJ4d'> <SocialIcon type="facebook" /></a>
                                    <a href='https://www.instagram.com/92limoservice?igsh=ZTZrYzZqcXg1NDR4&utm_source=qr'><SocialIcon type="instagram" /></a>
                                    <a href='https://www.youtube.com/@92Transportation'> <SocialIcon type="youtube" /></a>
                                    <a href='https://www.tiktok.com/@92.limo.service?_t=8lt2RW5JU9D&_r=1'>     <SocialIcon type="tikTok" /></a>


                                    {/* <SocialIcon type="twitter" /> */}



                                </div>
                                <img src="upload/pngegg.png" style={{ width: 170 }} alt="logo" />
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="footer-column three columns" id="footer-widget-2">
                <div
                  id="text-9"
                  className="widget widget_text limoking-item limoking-widget"
                >
                  <h3 className="limoking-widget-title">Contact Info</h3>
                  <div className="clear" />
                  <div className="textwidget">
                    Address: Baltimore, Maryland, USA 21228 8007

                  </div>
                </div>
              </div> */}
                    <div className="footer-column three columns" id="footer-widget-3">
                        <div
                            id="recent-posts-5"
                            className="widget widget_recent_entries limoking-item limoking-widget"
                        >
                            <h3 className="limoking-widget-title">Reservations</h3>
                            <div className="clear" />
                            <ul>
                                {
                                    Reservations.map((item) => <li>
                                    <Link to={item.route}>{item.value}</Link>
                                </li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="footer-column three columns" id="footer-widget-3">
                        <div
                            id="recent-posts-5"
                            className="widget widget_recent_entries limoking-item limoking-widget"
                        >
                            <h3 className="limoking-widget-title">Special Events</h3>
                            <div className="clear" />
                            <ul>
                                {
                                    Special.map((item) => <li>
                                    <Link to={item.route}>{item.value}</Link>
                                </li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="footer-column three columns" id="footer-widget-3">
                        <div
                            id="recent-posts-5"
                            className="widget widget_recent_entries limoking-item limoking-widget"
                        >
                            <h3 style={{ cursor: 'pointer' }} className="limoking-widget-title">Company</h3>
                            <div className="clear" />
                            <ul>
                                {
                                    cities.map((item) => <li>

                                        <Link to={item.route}>{item.value}</Link>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="clear" />
                </div>
                <div className="copyright-wrapper">
                    <div className="copyright-container container">
                        <div className="copyright-left">
                            Copyright © 2024 92 Transportation | Powered by 92 Transportation
                        </div>
                        <div className="clear" />
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Layout