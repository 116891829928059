import { Route, Routes } from "react-router-dom";
import Home from "../Home";
import About from "../About";
import Blog from "../Blog";
import ContactUs from "../ContactUs";
import PrivacyPolicy from "../privacyPolicy";
import TermsAndConditions from "../terms-conditions";
import BookNow from "../BookNow";
import Weedinglimo from "../Weeedinglimo";
import Airporttransfers from "../Airporttransfers";
import Winetours from "../Winetours";
import Partylikerockstar from "../Partylikerockstar";
import PromGraduation from "../PromGraduation";
import Corporatetravel from "../Corporatetravel";
import Locations from "../Locations";
import Baltimore from "../Baltimore";
import Login from "../Login";
import Maryland from "../Maryland";
import WashingtonDC from "../WashingtonDC";
import ChicagoIL from "../ChicagoIL";
import PhiladelphiaPA from "../PhiladelphiaPA";
import NewOrleansLA from "../NewOrleansLA";
const Routers = () => {
	return (
		<Routes>
			<Route path="/" element={[<Home />,]} />
			<Route path="/about-us" element={<About />} />
			<Route path="/blog" element={<Blog />} />
			<Route path="/contact-us" element={<ContactUs />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/terms-conditions" element={<TermsAndConditions />} />	
			<Route path="/BookNow" element={<BookNow />} />	
			<Route path="/Weedinglimo" element={<Weedinglimo />} />
			<Route path="/Airporttransfers" element={<Airporttransfers />} />
			<Route path="/Winetours" element={<Winetours />} />
			<Route path="/Partylikerockstar" element={<Partylikerockstar />} />
			<Route path="/Corporatetravel" element={<Corporatetravel />} />
			<Route path="/PromGraduation" element={<PromGraduation />} />
			<Route path="/Locations" element={<Locations />} />
			<Route path="/Baltimore" element={<Baltimore />} />
			<Route path="/Maryland" element={<Maryland />} />
			<Route path="/Login" element={<Login />} />
			<Route path="/ChicagoIL" element={<ChicagoIL />} />
			<Route path="/PhiladelphiaPA" element={<PhiladelphiaPA />} />
			<Route path="/NewOrleansLA" element={<NewOrleansLA />} />
			<Route path="/WashingtonDC" element={<WashingtonDC />} />





		</Routes>
	);
};

export default Routers;