import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faStar } from "@fortawesome/free-solid-svg-icons";
import Slider from 'react-slick'
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import AOS from "aos";
import { FaCarAlt, FaChevronLeft, FaChevronRight, FaLongArrowAltLeft, FaTimes } from 'react-icons/fa';
import FooterSlider from './FooterSlider';
import BackToTopButton from './BakctoTop'
import ScrollToTop from './ScrollToTop';
import './Home.css'
import { useLocation } from 'react-router-dom';
import SliderSection from './Slider/SliderSection';
import MainSection from './HomeSlider.js/MainSection';



const isMobile = typeof window !== 'undefined' && window.innerWidth < 770

const Home = () => {

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 400,
            left: 0,
            behavior: 'smooth'
        });
    };
    useEffect(() => {
        // Add the widget loader script to the page
        const script = document.createElement("script");
        script.src = "https://book.mylimobiz.com/v4/widgets/widget-loader.js";
        script.type = "text/javascript";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          // Clean up the script if it's no longer needed
          document.body.removeChild(script);
        };
      }, []); // Empty dependency array ensures this runs only once
    const Modal = ({ onClose }) => {
        return (
            <div className="custom-modal-overlay">
                <div className="custom-modal">
                    <div className="custom-modal-header">
                        <h2></h2>
                        <button className="custom-close-button" onClick={onClose}><FaTimes /></button>
                    </div>
                    <div className="custom-modal-content">
                        <Helmet>
                            <script type="text/javascript" src="https://book.mylimobiz.com/v4/widgets/widget-loader.js"></script>
                        </Helmet>
                    </div>
                </div>
            </div>
        );
    };




    const settings = {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        // arrows:true,
        slidesToScroll: 1,
        prevArrow: <FaChevronLeft />,
        nextArrow: <FaChevronRight />
    };



    React.useEffect(() => {
        AOS.init();
    }, []);

    const location = useLocation();

    const scrollToTopFun = (scrollState) => {

        window.scrollTo({
            top:scrollState ? 500 : 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const scrollState = location.state?.scrolltoTop
        scrollToTopFun(scrollState);
    }, [location.pathname]);

    return (

        <>
            <ScrollToTop />
            <BackToTopButton />
            <Slider {...settings} swipe={true} >
                <div class="item">
                    <div className='carousel_content'>
                        <img src='upload/slider-item-1.jpg' style={{ height: isMobile ? '229px' : 'auto' }} />
                        <div className='content_text'>
                            <div
                                className="ms-layer msp-cn-2-1"
                                style={{}}
                                data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                                data-duration={375}
                                data-ease="easeOutQuint"
                                data-offset-x={0}
                                data-offset-y={-111}
                                data-origin="mc"
                                data-position="normal"
                            >
                                The Best Limousine
                            </div>
                            <div
                                className="ms-layer msp-cn-2-2"
                                style={{}}
                                data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                                data-duration={375}
                                data-delay={300}
                                data-ease="easeOutQuint"
                                data-offset-x={0}
                                data-offset-y={-36}
                                data-origin="mc"
                                data-position="normal"
                            >
                                Service In Maryland, Washington DC & Virginia
                            </div>
                            <div
                                className="ms-layer msp-cn-2-3"
                                style={{}}
                                data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                                data-duration={325}
                                data-delay={612}
                                data-ease="easeOutQuint"
                                data-offset-x={0}
                                data-offset-y={62}
                                data-origin="mc"
                                data-position="normal"
                            >
                                92 Limo
                            </div>
                            {/* <div className="limoking-item-title-content-icon-divider-wrapper">
                                <div className='limoking-item-title-center-icon-divider'></div>
                                <div
                                    className="ms-layer msp-cn-2-9"
                                    data-duration={325}
                                    data-delay={912}
                                    data-ease="easeOutQuint"
                                    data-offset-x={0}
                                    data-offset-y={154}
                                    data-origin="mc"
                                    data-position="normal"
                                >
                                    <a style={{ color: "#fff", margin: '8px' }} href="#">
                                        Learn More
                                    </a>
                                </div>
                                <div className='limoking-item-title-center-icon-divider'></div>

                            </div> */}

                        </div>

                    </div>
                </div>
                <div class="item">
                    <div className='carousel_content'>
                        <img src='upload/slider-item-2.jpg' style={{ height: isMobile ? '229px' : 'auto' }} />
                        <div className='content_text'>
                            <div>
                                <div
                                    data-aos="fade-left"
                                    data-aos-delay="100"
                                    className="ms-layer msp-cn-2-10"
                                    style={{}}
                                    data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                                    data-duration={437}
                                    data-ease="easeOutQuint"
                                    data-offset-x={3}
                                    data-offset-y={42}
                                    data-origin="ml"
                                    data-position="normal"
                                >
                                    Your global chauffeur service in <br /> Maryland ,Washington DC <br /> and  Virginia
                                </div>
                                {/* <div
                                    data-aos="fade-left"
                                    data-aos-delay="200"
                                    className="ms-layer msp-cn-2-11"
                                    style={{ marginBottom: '20px' }}
                                    data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                                    data-duration={437}
                                    data-delay={325}
                                    data-ease="easeOutQuint"
                                    data-offset-x={2}
                                    data-offset-y={120}
                                    data-origin="ml"
                                    data-position="normal"
                                >
                                    92 Limo offers superb limo service in Maryland, Washington DC & Virginia and
                                    Manhatatan. &nbsp;We are the most popular and has
                                    <br />
                                    been chosen by many important people. We also provide
                                    premier service to the airport, wedding,
                                    <br />
                                    casino, sport event, proms etc.
                                </div> */}
                                {/* <a
                                    data-aos="fade-left"
                                    data-aos-delay="300"
                                    href="fleet-3-columns.html"
                                    target="_self"
                                    className="ms-layer msp-cn-1-12 ms-btn ms-btn-round ms-btn-n msp-preset-btn-159"
                                    data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                                    data-duration={412}
                                    data-delay={650}
                                    style={{marginTop:'8px'}}
                                    data-ease="easeOutQuint"
                                    data-type="button"
                                    data-offset-x={2}
                                    data-offset-y={204}
                                    data-origin="ml"
                                    data-position="normal"
                                >
                                    See Our Fleet
                                </a> */}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="item">
                    <div className='carousel_content'>
                        <img src='upload/slider-item-3.jpg' style={{ height: isMobile ? '229px' : 'auto' }} />
                        <div className='content_text'>
                            <div>
                                <div
                                    className="ms-layer msp-cn-2-13"
                                    style={{}}
                                    data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                                    data-duration={362}
                                    data-ease="easeOutQuint"
                                    data-offset-x={0}
                                    data-offset-y={75}
                                    data-origin="mc"
                                    data-position="normal"
                                >
                                    Give Yourself
                                </div>
                                <div className="limoking-item-title-content-icon-divider-wrapper">
                                    <div style={{ borderRadius: '4px' }} className='limoking-item-title-center-icon-divider'></div> </div>
                                <div
                                    className="ms-layer msp-cn-2-14"
                                    style={{}}
                                    data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                                    data-duration={362}
                                    data-delay={575}
                                    data-ease="easeOutQuint"
                                    data-offset-x={0}
                                    data-offset-y={186}
                                    data-origin="mc"
                                    data-position="normal"
                                >
                                    A New Experience
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div
                    className="item"
                    data-delay={7}
                    data-fill-mode="fill"
                >
                    <img
                        src="upload/slider-item-1.jpg"
                        alt="blank"
                        title=""
                        data-src="upload/slider-item-1.jpg"
                    />
                    <div
                        className="ms-layer msp-cn-2-1"
                        style={{}}
                        data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={375}
                        data-ease="easeOutQuint"
                        data-offset-x={0}
                        data-offset-y={-111}
                        data-origin="mc"
                        data-position="normal"
                    >
                        The Best Limos
                    </div>
                    <div
                        className="ms-layer msp-cn-2-2"
                        style={{}}
                        data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={375}
                        data-delay={300}
                        data-ease="easeOutQuint"
                        data-offset-x={0}
                        data-offset-y={-36}
                        data-origin="mc"
                        data-position="normal"
                    >
                        In New York
                    </div>
                    <div
                        className="ms-layer msp-cn-2-3"
                        style={{}}
                        data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={325}
                        data-delay={612}
                        data-ease="easeOutQuint"
                        data-offset-x={0}
                        data-offset-y={62}
                        data-origin="mc"
                        data-position="normal"
                    >
                        Limo King
                    </div>
                    <img
                        className="ms-layer"
                        src="plugins/masterslider/public/assets/css/blank.gif"
                        data-src="upload/slider-divider1.png"
                        alt="blank"
                        style={{}}
                        data-duration={337}
                        data-delay={912}
                        data-ease="easeOutQuint"
                        data-type="image"
                        data-offset-x={-114}
                        data-offset-y={153}
                        data-origin="mc"
                        data-position="normal"
                    />
                    <img
                        className="ms-layer"
                        src="plugins/masterslider/public/assets/css/blank.gif"
                        data-src="upload/slider-divider1.png"
                        alt="blank"
                        style={{}}
                        data-duration={325}
                        data-delay={900}
                        data-ease="easeOutQuint"
                        data-type="image"
                        data-offset-x={116}
                        data-offset-y={153}
                        data-origin="mc"
                        data-position="normal"
                    />
                    <div
                        className="ms-layer msp-cn-2-9"
                        style={{}}
                        data-duration={325}
                        data-delay={912}
                        data-ease="easeOutQuint"
                        data-offset-x={0}
                        data-offset-y={154}
                        data-origin="mc"
                        data-position="normal"
                    >
                        <a style={{ color: "#fff" }} href="#">
                            Learn More
                        </a>
                    </div>
                </div>
                <div
                    className="item"
                    data-delay={7}
                    data-fill-mode="fill"
                >
                    <img
                        src="plugins/masterslider/public/assets/css/blank.gif"
                        alt="blank"
                        title=""
                        data-src="upload/slider-item-2.jpg"
                    />
                    <div
                        className="ms-layer msp-cn-2-10"
                        style={{}}
                        data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={437}
                        data-ease="easeOutQuint"
                        data-offset-x={3}
                        data-offset-y={42}
                        data-origin="ml"
                        data-position="normal"
                    >
                        We Are #1 Limo Services
                    </div>
                    <div
                        className="ms-layer msp-cn-2-11"
                        style={{}}
                        data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={437}
                        data-delay={325}
                        data-ease="easeOutQuint"
                        data-offset-x={2}
                        data-offset-y={120}
                        data-origin="ml"
                        data-position="normal"
                    >
                        92 Limo offers superb limo service in New York and
                        Manhatatan. &nbsp;We are the most popular and has
                        <br />
                        been chosen by many important people. We also provide
                        premier service to the airport, wedding,
                        <br />
                        casino, sport event, proms etc.
                    </div>
                    <a
                        href="fleet-3-columns.html"
                        target="_self"
                        className="ms-layer msp-cn-1-12 ms-btn ms-btn-round ms-btn-n msp-preset-btn-159"
                        data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={412}
                        data-delay={650}
                        data-ease="easeOutQuint"
                        data-type="button"
                        data-offset-x={2}
                        data-offset-y={204}
                        data-origin="ml"
                        data-position="normal"
                    >
                        See Our Fleet
                    </a>
                </div>
                <div
                    className="item"
                    data-delay={7}
                    data-fill-mode="fill"
                >
                    <img
                        src="plugins/masterslider/public/assets/css/blank.gif"
                        alt="blank"
                        title=""
                        data-src="upload/slider-item-3.jpg"
                    />
                    <div
                        className="ms-layer msp-cn-2-13"
                        style={{}}
                        data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={362}
                        data-ease="easeOutQuint"
                        data-offset-x={0}
                        data-offset-y={75}
                        data-origin="mc"
                        data-position="normal"
                    >
                        Give Yourself
                    </div>
                    <img
                        className="ms-layer"
                        src="plugins/masterslider/public/assets/css/blank.gif"
                        data-src="upload/slider-3-divider.jpg"
                        alt="blank"
                        style={{}}
                        data-duration={312}
                        data-delay={325}
                        data-ease="easeOutQuint"
                        data-type="image"
                        data-offset-x={0}
                        data-offset-y={139}
                        data-origin="mc"
                        data-position="normal"
                    />
                    <div
                        className="ms-layer msp-cn-2-14"
                        style={{}}
                        data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                        data-duration={362}
                        data-delay={575}
                        data-ease="easeOutQuint"
                        data-offset-x={0}
                        data-offset-y={186}
                        data-origin="mc"
                        data-position="normal"
                    >
                        A New Experience
                    </div>
                </div> */}
            </Slider>
            <div data-aos="fade-up"
                data-aos-delay="100" className='flexDirection'>
                <section style={{ marginTop: '15px' }} className='scriptWidth'>
                    <Helmet>
                      <script type="text/javascript" src="https://book.mylimobiz.com/v4/widgets/widget-loader.js"></script>
                      </Helmet>
                    <a
                        href="https://book.mylimobiz.com/v4/92transp"
                        data-ores-widget="website"
                        data-ores-alias="92transp"
                    >
                        Online Reservations
                    </a>
                </section>
                <div style={{ marginTop: '15px' }} className="sectionsWidth uk-background-muted">
                    <div className="main-search-section">
                        <div className="search-result">
                            <div className='bookingMain'>

                                <div className='enhanced-content-header' >

                                    <p className='mainFormName'>
                                        START YOUR JOURNEY
                                    </p>
                                    <h2 className='enhanced-content-header__text'>Book Premium Chauffeur-drive
                                    </h2>
                                    <div className='widthForm'>
                                        <div style={{ maxWidth: '80%' }} className="enhanced-rich-text enhanced-content-header__subtext enhanced-content-header__subtext--mixed promo-wrapper__subtext enhanced-content-header__subtext--bottom">Arrange your Chauffeur‑drive pick‑up at least 12 hours ahead of your flight. You can add it to your itinerary when you book your flight, or later through Manage Your Booking. When you land, check in with your driver at our Chauffeur‑drive desks.
                                        </div>
                                    </div>
                                    <a onClick={() => isMobile ? scrollToTopFun(true) : console.log('ok')}
                                        className="about-us-read-more limoking-button large"
                                    >
                                        <FaLongArrowAltLeft style={{ marginRight: '5px' }} />
                                        Book Now
                                    </a>

                                </div>

                            </div>
                            <div style={{ marginTop: '10px' }} className="search-result__list">
                                <div className="limoking-item-title-wrapper limoking-item limoking-nav-container limoking-center-icon-divider limoking-medium">
                                    <div className="limoking-item-title-container container">
                                        <div className="limoking-item-title-head-inner">
                                            <h3 className="limoking-item-title limoking-skin-title limoking-skin-border">
                                                Our Fleet
                                            </h3>
                                        </div>
                                        <div className="limoking-nav-title">
                                            <div className="limoking-item-title-content-icon-divider-wrapper">
                                                <div className="limoking-item-title-center-icon-divider" />
                                                <FaCarAlt style={{ marginLeft: '12px', marginRight: '12px' }} />
                                                <div className="limoking-item-title-center-icon-divider" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <SliderSection />

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="content-wrapper">
                <div className="limoking-content">
                    <div className="with-sidebar-wrapper">
                        {/* <section id="content-section-1">
          <div
            className="limoking-full-size-wrapper gdlr-show-all no-skin"
            style={{ paddingBottom: 0, backgroundColor: "#ffffff" }}
          >
            <div
              className="limoking-master-slider-item limoking-slider-item limoking-item"
              style={{ marginBottom: 0 }}
            >
              <div
                id="P_MS5c9b6f3967f07"
                className="master-slider-parent ms-parent-id-1"
              >
                <div
                  id="MS5c9b6f3967f07"
                  className="master-slider ms-skin-default"
                >
                  <div
                    className="ms-slide"
                    data-delay={7}
                    data-fill-mode="fill"
                  >
                    <img
                      src="plugins/masterslider/public/assets/css/blank.gif"
                      alt="blank"
                      title=""
                      data-src="upload/slider-item-1.jpg"
                    />
                    <div
                      className="ms-layer msp-cn-2-1"
                      style={{}}
                      data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={375}
                      data-ease="easeOutQuint"
                      data-offset-x={0}
                      data-offset-y={-111}
                      data-origin="mc"
                      data-position="normal"
                    >
                      The Best Limos
                    </div>
                    <div
                      className="ms-layer msp-cn-2-2"
                      style={{}}
                      data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={375}
                      data-delay={300}
                      data-ease="easeOutQuint"
                      data-offset-x={0}
                      data-offset-y={-36}
                      data-origin="mc"
                      data-position="normal"
                    >
                      In New York
                    </div>
                    <div
                      className="ms-layer msp-cn-2-3"
                      style={{}}
                      data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={325}
                      data-delay={612}
                      data-ease="easeOutQuint"
                      data-offset-x={0}
                      data-offset-y={62}
                      data-origin="mc"
                      data-position="normal"
                    >
                      Limo King
                    </div>
                    <img
                      className="ms-layer"
                      src="plugins/masterslider/public/assets/css/blank.gif"
                      data-src="upload/slider-divider1.png"
                      alt="blank"
                      style={{}}
                      data-duration={337}
                      data-delay={912}
                      data-ease="easeOutQuint"
                      data-type="image"
                      data-offset-x={-114}
                      data-offset-y={153}
                      data-origin="mc"
                      data-position="normal"
                    />
                    <img
                      className="ms-layer"
                      src="plugins/masterslider/public/assets/css/blank.gif"
                      data-src="upload/slider-divider1.png"
                      alt="blank"
                      style={{}}
                      data-duration={325}
                      data-delay={900}
                      data-ease="easeOutQuint"
                      data-type="image"
                      data-offset-x={116}
                      data-offset-y={153}
                      data-origin="mc"
                      data-position="normal"
                    />
                    <div
                      className="ms-layer msp-cn-2-9"
                      style={{}}
                      data-duration={325}
                      data-delay={912}
                      data-ease="easeOutQuint"
                      data-offset-x={0}
                      data-offset-y={154}
                      data-origin="mc"
                      data-position="normal"
                    >
                      <a style={{ color: "#fff" }} href="#">
                        Learn More
                      </a>
                    </div>
                  </div>
                  <div
                    className="ms-slide"
                    data-delay={7}
                    data-fill-mode="fill"
                  >
                    <img
                      src="plugins/masterslider/public/assets/css/blank.gif"
                      alt="blank"
                      title=""
                      data-src="upload/slider-item-2.jpg"
                    />
                    <div
                      className="ms-layer msp-cn-2-10"
                      style={{}}
                      data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={437}
                      data-ease="easeOutQuint"
                      data-offset-x={3}
                      data-offset-y={42}
                      data-origin="ml"
                      data-position="normal"
                    >
                      We Are #1 Limo Services
                    </div>
                    <div
                      className="ms-layer msp-cn-2-11"
                      style={{}}
                      data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={437}
                      data-delay={325}
                      data-ease="easeOutQuint"
                      data-offset-x={2}
                      data-offset-y={120}
                      data-origin="ml"
                      data-position="normal"
                    >
                      92 Limo offers superb limo service in New York and
                      Manhatatan. &nbsp;We are the most popular and has
                      <br />
                      been chosen by many important people. We also provide
                      premier service to the airport, wedding,
                      <br />
                      casino, sport event, proms etc.
                    </div>
                    <a
                      href="fleet-3-columns.html"
                      target="_self"
                      className="ms-layer msp-cn-1-12 ms-btn ms-btn-round ms-btn-n msp-preset-btn-159"
                      data-effect="t(true,150,n,n,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={412}
                      data-delay={650}
                      data-ease="easeOutQuint"
                      data-type="button"
                      data-offset-x={2}
                      data-offset-y={204}
                      data-origin="ml"
                      data-position="normal"
                    >
                      See Our Fleet
                    </a>
                  </div>
                  <div
                    className="ms-slide"
                    data-delay={7}
                    data-fill-mode="fill"
                  >
                    <img
                      src="plugins/masterslider/public/assets/css/blank.gif"
                      alt="blank"
                      title=""
                      data-src="upload/slider-item-3.jpg"
                    />
                    <div
                      className="ms-layer msp-cn-2-13"
                      style={{}}
                      data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={362}
                      data-ease="easeOutQuint"
                      data-offset-x={0}
                      data-offset-y={75}
                      data-origin="mc"
                      data-position="normal"
                    >
                      Give Yourself
                    </div>
                    <img
                      className="ms-layer"
                      src="plugins/masterslider/public/assets/css/blank.gif"
                      data-src="upload/slider-3-divider.jpg"
                      alt="blank"
                      style={{}}
                      data-duration={312}
                      data-delay={325}
                      data-ease="easeOutQuint"
                      data-type="image"
                      data-offset-x={0}
                      data-offset-y={139}
                      data-origin="mc"
                      data-position="normal"
                    />
                    <div
                      className="ms-layer msp-cn-2-14"
                      style={{}}
                      data-effect="t(true,n,n,500,n,n,n,n,n,n,n,n,n,n,n)"
                      data-duration={362}
                      data-delay={575}
                      data-ease="easeOutQuint"
                      data-offset-x={0}
                      data-offset-y={186}
                      data-origin="mc"
                      data-position="normal"
                    >
                      A New Experience
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
            <div className="clear" />
          </div>
          <div className="clear" />
        </section> */}
                        <section id="content-section-2">
                            <div
                                className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-dark-skin"
                                id="limoking-parallax-wrapper-1"
                                data-bgspeed={0}
                                style={{
                                    backgroundImage: 'url("upload/bg11.png")',
                                    backgroundRepeat: "repeat-x",
                                    paddingTop: 230,
                                    paddingBottom: 180
                                }}
                            >
                                <div data-aos="fade-up"
                                    data-aos-delay="100"   >
                                    <div className="limoking-title-item">
                                        <div className="limoking-item-title-wrapper limoking-item limoking-center-icon-divider limoking-large">
                                            <div className="limoking-item-title-container container">
                                                <div className="limoking-item-title-head-inner">
                                                    <h3 style={{ color: '#d59d23' }} className="limoking-item-title limoking-skin-title limoking-skin-border">
                                                        We serve you like a king
                                                    </h3>
                                                </div>
                                                <div className="limoking-nav-title">
                                                    <div className="limoking-item-title-content-icon-divider-wrapper">
                                                        <div style={{ borderColor: '#a6dde8', borderWidth: '3px' }} className="limoking-item-title-center-icon-divider" />
                                                        <div style={{ borderColor: '#a6dde8', borderWidth: '3px' }} className="limoking-item-title-center-icon-divider" />
                                                    </div>
                                                </div>
                                                <div style={{ fontWeight: '500', padding: '6px', borderRadius: '100px' }} className="limoking-item-title-caption limoking-skin-info">
                                                    We offer you a super VIP experience in Maryland, Virginia and Washington DC.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                    <div className="clear" />
                                </div>
                            </div>
                            <div className="clear" />
                        </section>
                        <MainSection />
                        <section id="content-section-4">
                            <div
                                className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-darkblue"
                                id="limoking-parallax-wrapper-2"
                                data-bgspeed={0}
                                style={{
                                    backgroundImage: 'url("upload/about-us-bg1.jpg")',
                                    paddingTop: 95,
                                    paddingBottom: 50
                                }}
                            >
                                <div data-aos="fade-up"
                                    data-aos-delay="100" className="container">
                                    <div className="six columns">
                                        <div className="limoking-item limoking-about-us-item limoking-with-divider">
                                            <div className="about-us-title-wrapper">
                                                <h3 data-aos="fade-up"
                                                    data-aos-delay="100" className="about-us-title">
                                                    Luxuriate in the Exceptional Comfort and Sophistication of Our Exclusive Chauffeur Service
                                                </h3>
                                                <div className="about-us-title-divider" />
                                            </div>
                                            <div className="about-us-content-wrapper">
                                                <div className="about-us-content limoking-skin-content">
                                                    <p data-aos="fade-up"
                                                        data-aos-delay="100">
                                                        Experience luxury with our exclusive chauffeur service. From our meticulously maintained vehicles to our professional chauffeurs, every detail is designed to ensure your journey is exceptional. Trust us to elevate your experience with unmatched luxury and service.
                                                    </p>
                                                </div>
                                                <a onClick={() => scrollToTopFun(true)}
                                                    className="about-us-read-more limoking-button large"
                                                >
                                                    Book Now
                                                </a>
                                            </div>
                                            {showModal && <Modal onClose={closeModal} />}
                                            <div className="clear" />
                                        </div>
                                    </div>
                                    <div className="clear" />
                                </div>
                            </div>
                            <div className="clear" />
                        </section>
                        <section id="content-section-5">
                            <div
                                className="limoking-color-wrapper gdlr-show-all limoking-skin-dark-skin"
                                style={{
                                    backgroundColor: "#D59D23",
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <div data-aos="fade-up"
                                    data-aos-delay="100" className="container">
                                    <div className="limoking-stunning-item-ux limoking-ux">
                                        <div className="limoking-item limoking-stunning-item">
                                            <div className="stunning-item-content">
                                                <h2 className="stunning-item-title">
                                                    What about pricing?
                                                </h2>
                                                <div className="stunning-item-caption limoking-skin-content">
                                                    Don’t worry. We provide very nice deals and the most
                                                    competative price just for you!
                                                </div>
                                            </div>
                                            <a
                                                className="stunning-item-button limoking-info-font"
                                                style={{ color: "#fff", background: "black", cursor: 'pointer' }}
                                                onClick={() => scrollToTopFun(true)}
                                            >
                                                Check Rates
                                                <img src='images/rightArrowIcon.png' style={{ width: '20px', marginLeft: '10px' }} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                    <div className="clear" />
                                </div>
                            </div>
                            <div className="clear" />
                        </section>
                        <section id="content-section-6">
                            <div
                                className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-dark-skin"
                                id="limoking-parallax-wrapper-3"
                                data-bgspeed={0}
                                style={{
                                    backgroundImage: 'url("upload/service-title-bg1.jpg")',
                                    paddingTop: 70
                                }}
                            >
                                <div data-aos="fade-up"
                                    data-aos-delay="100" className="container">
                                    <div className="limoking-title-item">
                                        <div className="limoking-item-title-wrapper limoking-item limoking-center-divider limoking-medium">
                                            <div className="limoking-item-title-container container">
                                                <div className="limoking-item-title-head-inner">
                                                    <div className="limoking-item-title-center-divider limoking-left" />
                                                    <h3 className="limoking-item-title limoking-skin-title limoking-skin-border">
                                                        Our Services
                                                    </h3>
                                                    <div className="limoking-item-title-center-divider limoking-right" />
                                                </div>
                                                {/* <div className="limoking-item-title-caption limoking-skin-info">
                                                    Magna Risus Vestibulum Vulputate
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                    <div className="clear" />
                                </div>
                            </div>
                            <div className="clear" />
                        </section>
                        <section data-aos="fade-up"
                            data-aos-delay="100" id="content-section-7">
                            <div
                                className="limoking-color-wrapper gdlr-show-all no-skin"
                                style={{
                                    backgroundColor: "#ffffff",
                                    paddingTop: 70,
                                    paddingBottom: 20
                                }}
                            >
                                <div className="container">
                                    <div className="four columns">
                                        <div className="limoking-ux column-service-ux">
                                            <div
                                                className="limoking-item limoking-column-service-item limoking-medium"
                                                style={{ marginBottom: 40 }}
                                            >
                                                <Link to="/Winetours">
                                                <div className="column-service-image">
                                                    <img
                                                        src="upload/service-icon-11.png"
                                                        alt="service-icon-11"
                                                        width={41}
                                                        height={46}
                                                    />
                                                </div>
                                                <div className="column-service-content-wrapper">
                                                    <h3 className="column-service-title">Night Parties</h3>
                                                    <div className="column-service-content limoking-skin-content">
                                                        <p>
                                                            Turn heads as you arrive at your night out in style. Our chauffeur service in Maryland, Virginia, and Washington DC ensures a seamless journey, allowing you to focus on the fun.
                                                        </p>
                                                    </div>

                                                </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="four columns">
                                        <div className="limoking-ux column-service-ux">
                                            <div
                                                className="limoking-item limoking-column-service-item limoking-medium"
                                                style={{ marginBottom: 40 }}
                                            >
                                            <Link to="/Weedinglimo">
                                                <div className="column-service-image">
                                                    <img
                                                        src="upload/service-icon-21.png"
                                                        alt="service-icon-21"
                                                        width={41}
                                                        height={46}
                                                    />
                                                </div>
                                                
                                                <div className="column-service-content-wrapper">
                                                    <h3 className="column-service-title">Weddings</h3>
                                                    <div className="column-service-content limoking-skin-content">
                                                        <p>
                                                            Elevate your special day with our chauffeur service. Whether in Maryland, Virginia, or Washington DC, our professional chauffeurs ensure a smooth and luxurious ride, adding an extra touch of elegance to your wedding.
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="four columns">
                                        <div className="limoking-ux column-service-ux">
                                            <div
                                                className="limoking-item limoking-column-service-item limoking-medium"
                                                style={{ marginBottom: 40 }}
                                            >
                                                <div className="column-service-image">
                                                    <img
                                                        src="upload/service-icon-31.png"
                                                        alt="service-icon-31"
                                                        width={41}
                                                        height={46}
                                                    />
                                                </div>
                                                <div className="column-service-content-wrapper">
                                                    <h3 className="column-service-title">Casinos</h3>
                                                    <div className="column-service-content limoking-skin-content">
                                                        <p>
                                                            92 Limo offers superb lim service in New York and
                                                            Manhatatan. We are the most popular and has been
                                                            chosen by many important people. We also provide
                                                            premier service to the airport, wedding, casino,
                                                            sport event, proms etc.
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="four columns">
                                        <div className="limoking-ux column-service-ux">
                                            <div
                                                className="limoking-item limoking-column-service-item limoking-medium"
                                                style={{ marginBottom: 40 }}
                                            >
                                                <Link to="/Partylikerockstar">
                                                <div className="column-service-image">
                                                    <img
                                                        src="upload/service-icon-41.png"
                                                        alt="service-icon-41"
                                                        width={41}
                                                        height={46}
                                                    />
                                                </div>
                                                <div className="column-service-content-wrapper">
                                                    <h3 className="column-service-title">Birthdays</h3>
                                                    <div className="column-service-content limoking-skin-content">
                                                        <p>
                                                            Make your birthday celebration unforgettable with our chauffeur service. Serving Maryland, Virginia, and Washington DC, our reliable chauffeurs ensure you arrive in comfort and style, ready to party.
                                                        </p>
                                                    </div>

                                                </div>
                                             </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                    <div className="four columns">
                                        <div className="limoking-ux column-service-ux">
                                            <div
                                                className="limoking-item limoking-column-service-item limoking-medium"
                                                style={{ marginBottom: 40 }}
                                            >
                                             <Link to="/PromGraduation">
                                                <div className="column-service-image">
                                                    <img
                                                        src="upload/service-icon-51.png"
                                                        alt="service-icon-51"
                                                        width={41}
                                                        height={46}
                                                    />
                                                </div>
                                                <div className="column-service-content-wrapper">
                                                    <h3 className="column-service-title">Proms</h3>
                                                    <div className="column-service-content limoking-skin-content">
                                                        <p>
                                                            Arrive in sophistication at your prom with our chauffeur service. Covering Maryland, Virginia, and Washington DC, our experienced chauffeurs guarantee a memorable entrance, making your prom night one to remember.
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="four columns">
                                        <div className="limoking-ux column-service-ux">
                                            <div
                                                className="limoking-item limoking-column-service-item limoking-medium"
                                                style={{ marginBottom: 40 }}
                                            >
                                                <Link to="/Airporttransfers">
                                                <div className="column-service-image">
                                                    <img
                                                        src="upload/service-icon-61.png"
                                                        alt="service-icon-61"
                                                        width={41}
                                                        height={46}
                                                    />
                                                </div>
                                                <div className="column-service-content-wrapper">
                                                    <h3 className="column-service-title">
                                                        Airport Transfers
                                                    </h3>
                                                    <div className="column-service-content limoking-skin-content">
                                                        <p>
                                                            Start or end your journey stress-free with our chauffeur service for airport transfers. Servicing Maryland, Virginia, and Washington DC, our punctual chauffeurs provide a smooth and efficient ride, ensuring a seamless travel experience.
                                                        </p>
                                                    </div>

                                                </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                </div>
                            </div>
                            <div className="clear" />
                        </section>
                        <section >
                            <div className='video-section'>
                                <div className="custom-video-background">
                                    <video autoPlay loop muted>
                                        <source src="upload/service-video-bg-n.webm"
                                            type="video/webm" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <div className="custom-content">
                                        <div className='Content-Top'>
                                            <div
                                                className="ms-layer msp-cn-1-1"
                                                style={{}}
                                                data-ease="easeOutQuint"
                                                data-offset-x={0}
                                                data-offset-y={-143}
                                                data-origin="mc"
                                                data-position="normal"
                                            >
                                                Or anywhere you need us to take
                                            </div>
                                            <div
                                                className="ms-layer msp-cn-1-2"
                                                style={{}}
                                                data-ease="easeOutQuint"
                                                data-offset-x={0}
                                                data-offset-y={-58}
                                                data-origin="mc"
                                                data-position="normal"
                                            >

                                                we  take you to anywhere you want to go.
                                            </div>
                                            <div
                                                className="ms-layer msp-cn-1-3"
                                                style={{}}
                                                data-ease="easeOutQuint"
                                                data-offset-x={0}
                                                data-offset-y={24}
                                                data-origin="mc"
                                                data-position="normal"
                                            >
                                                Call Now +1 667-400-0092
                                            </div>
                                            {/* <img
                                                    className="ms-layer"
                                                    src="plugins/masterslider/public/assets/css/blank.gif"
                                                    data-src="upload/slider-divider.png"
                                                    alt="blank"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-type="image"
                                                    data-offset-x={81}
                                                    data-offset-y={92}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                />
                                                <img
                                                    className="ms-layer"
                                                    src="plugins/masterslider/public/assets/css/blank.gif"
                                                    data-src="upload/slider-divider.png"
                                                    alt="blank"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-type="image"
                                                    data-offset-x={-79}
                                                    data-offset-y={92}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                /> */}
                                            <div className="limoking-nav-title">
                                                <div className="limoking-item-title-content-icon-divider-wrapper">
                                                    <div className="limoking-item-title-center-icon-divider" />
                                                    <span style={{ color: '#d59d23' }}>OR</span>
                                                    {/* <FaCarAlt style={{ marginLeft: '12px', marginRight: '12px' }} /> */}
                                                    <div className="limoking-item-title-center-icon-divider" />
                                                </div>
                                            </div>

                                            <a
                                            onClick={() => scrollToTopFun(true)}
                                                style={{ maxWidth: 'max-content',cursor:'pointer' }}
                                                // href="service-rates.html"
                                                target="_self"
                                                className="ms-layer msp-cn-2-7 ms-btn ms-btn-round ms-btn-n msp-preset-btn-159"
                                            >
                                                Book Online
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <section id="content-section-8">
                            <div
                                className="limoking-full-size-wrapper gdlr-show-all no-skin"
                                style={{ paddingBottom: 0, backgroundColor: "#ffffff" }}
                            >
                                <div
                                    className="limoking-master-slider-item limoking-slider-item limoking-item"
                                    style={{ marginBottom: 0 }}
                                >
                                    <div
                                        id="P_MS5c9b6f396d4d0"
                                        className="master-slider-parent ms-parent-id-2"
                                    >
                                        <div
                                            id="MS5c9b6f396d4d0"
                                            className="master-slider ms-skin-default"
                                        >
                                            <div
                                                className="ms-slide"
                                                data-delay={7}
                                                data-fill-mode="fill"
                                            >
                                                <img
                                                    src="plugins/masterslider/public/assets/css/blank.gif"
                                                    alt="blank"
                                                    title=""
                                                    data-src="upload/shutterstock_77669479.jpg"
                                                />{" "}
                                                <video
                                                    data-autopause="false"
                                                    data-mute="true"
                                                    data-loop="true"
                                                    data-fill-mode="fill"
                                                >
                                                    <source src="upload/service-video-bg-n.webm"
                                                        type="video/webm" />
                                                </video>
                                                <div
                                                    className="ms-layer msp-cn-1-1"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-offset-x={0}
                                                    data-offset-y={-143}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                >
                                                    Or anywhere you need us to take
                                                </div>
                                                <div
                                                    className="ms-layer msp-cn-1-2"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-offset-x={0}
                                                    data-offset-y={-58}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                >
                                                    Not only taking to night parties, weddings, casinos,
                                                    birthdays but
                                                    <br />
                                                    we also take you to anywhere you want to go.
                                                </div>
                                                <div
                                                    className="ms-layer msp-cn-1-3"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-offset-x={0}
                                                    data-offset-y={24}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                >
                                                    Call Now +1 667-400-0092
                                                </div>
                                                <img
                                                    className="ms-layer"
                                                    src="plugins/masterslider/public/assets/css/blank.gif"
                                                    data-src="upload/slider-divider.png"
                                                    alt="blank"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-type="image"
                                                    data-offset-x={81}
                                                    data-offset-y={92}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                />
                                                <img
                                                    className="ms-layer"
                                                    src="plugins/masterslider/public/assets/css/blank.gif"
                                                    data-src="upload/slider-divider.png"
                                                    alt="blank"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-type="image"
                                                    data-offset-x={-79}
                                                    data-offset-y={92}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                />
                                                <div
                                                    className="ms-layer msp-cn-1-6"
                                                    style={{}}
                                                    data-ease="easeOutQuint"
                                                    data-offset-x={0}
                                                    data-offset-y={95}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                >
                                                    OR
                                                </div>
                                                <a
                                                    href="service-rates.html"
                                                    target="_self"
                                                    className="ms-layer msp-cn-2-7 ms-btn ms-btn-round ms-btn-n msp-preset-btn-159"
                                                    data-ease="easeOutQuint"
                                                    data-type="button"
                                                    data-offset-x={0}
                                                    data-offset-y={173}
                                                    data-origin="mc"
                                                    data-position="normal"
                                                >
                                                    Book Online
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear" />
                                <div className="clear" />
                            </div>
                            <div className="clear" />
                        </section> */}
                        {/*   <section id="content-section-9">
          <div
            className="limoking-color-wrapper gdlr-show-all no-skin"
            style={{
              backgroundColor: "#f1f1f1",
              paddingTop: 20,
              paddingBottom: 10
            }}
          >
            <div className="container">
              <div className="limoking-banner-item-wrapper">
                <div
                  className="limoking-banner-images limoking-item"
                  style={{ marginBottom: 0 }}
                >
                  <div
                    className="flexslider"
                    data-pausetime={7000}
                    data-slidespeed={600}
                    data-effect="fade"
                    data-columns={5}
                    data-type="carousel"
                    data-nav-container="limoking-banner-images"
                  >
                    <ul className="slides">
                      <li>
                        <img
                          src="upload/banner-21.png"
                          alt="banner-21"
                          width={184}
                          height={100}
                        />
                      </li>
                      <li>
                        <img
                          src="upload/banner-11.png"
                          alt="banner-11"
                          width={184}
                          height={100}
                        />
                      </li>
                      <li>
                        <img
                          src="upload/banner-31.png"
                          alt="banner-31"
                          width={184}
                          height={100}
                        />
                      </li>
                      <li>
                        <img
                          src="upload/banner-51.png"
                          alt="banner-51"
                          width={184}
                          height={100}
                        />
                      </li>
                      <li>
                        <img
                          src="upload/banner-41.png"
                          alt="banner-41"
                          width={184}
                          height={100}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="clear" />
              <div className="clear" />
            </div>
          </div>
          <div className="clear" />
        </section> */}
                    </div>
                </div>
                <FooterSlider />
                <div className="clear" />
            </div>
        </>
    )
}

export default Home